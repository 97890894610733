<template>
    <contentCardIndex >
        <template slot="cardContent">
                <v-row align="center">
                    <v-col cols="12" md="3" lg="3" class="mx-0 px-0">
                         <alternativeTextField2
                            @onChanged="(v) => {trySearch(v);}" 
                            :valueInput="tableProps.search"
                            :rule="[]"
                            :placeholder="'Buscar'"
                            :label="''"
                            
                            :prependInnerIcon="'mdi-magnify'"
                            class="pick-3"
                            data-cy="search-input"
                        ></alternativeTextField2>
                       
                        
                    </v-col>
                    <v-col cols="12" md="3" lg="3" class="">
                        <alternativeAutocompleteOne
                            :items="works"
                            :valueInput="workSelected"
                            :loading="loadingWorks"
                           
                            @valueChange="(v) => {workSelected = v}"
                            @onChange="(v) => {index()}"
                            data-cy="workSelected-input"
                            :label="'Obra'"
                            classesContainer="width-auto"
                        ></alternativeAutocompleteOne>
                        
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col   cols="12" md="3" lg="3" xl="2" class="mx-0 ">
                        <v-row justify="end">
                           
                            <primaryButton data-cy="export-btn" :props="exportBtnData"></primaryButton>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="mt-md-0">
                    <v-col class="mx-0 px-0">
						<v-card class="index-card mx-0 px-0" flat color="transparent" >
                            <v-card-title class="body-1 font-weight-bold px-3 white--text">
                                <v-col class="px-0">
                                    <v-row  class="primary py-1 ">
                                        <v-col class="py-1 pb-0 text-sm-start text-center">
                                            <span>Subtotal: {{subtotal}}</span>
                                        </v-col>
                                        <v-col class="py-1 pb-0 text-sm-start text-center">
                                            <span>IVA: {{iva}}</span>
                                        </v-col>
                                        <v-col class="py-1 pb-0 text-sm-start text-center">
                                            <span>Total: {{total}}</span>
                                        </v-col>
                                       
                                       
                                    </v-row>
                                    
                                </v-col>
                            </v-card-title>
                            <v-card-text class="px-0">
                                <datatable data-cy="index-table" :props="tableProps" @methodHandler="method_handler">
                        
                                </datatable>
                            </v-card-text>
                        </v-card>
					</v-col>

                    
                </v-row>
                
        </template>

        
    
    </contentCardIndex>

        
</template>
<script>
import Utils from '../../../helpers/Utils'
export default {
    data(){
		return {
            subtotal: 0,
            iva: 0,
            total: 0,
            balance: 0,
            workSelected: '',
            works:[],
            loadingWorks:false,
			search: '',
            selectedID: '',
			
			tableProps: {
				headers: [
                    {
						text: 'Orden de compra',
						align: 'left break-words',
						value: 'PO_id',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Producto',
						align: 'left break-words',
						value: 'variant',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Proveedor',
						align: 'left break-words',
						value: 'supplier',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Cantidad',
						align: 'left break-words',
						value: 'quantity',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Unidad',
						align: 'left break-words',
						value: 'unit_name',
						class: 'table-b-border black--text'
					},
                    {
						text: 'P.U.',
						align: 'left break-words',
						value: 'price',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Subtotal',
						align: 'left break-words',
						value: 'subtotal',
						class: 'table-b-border black--text'
					},
                     {
						text: 'IVA',
						align: 'left break-words',
						value: 'iva',
						class: 'table-b-border black--text'
					},
                     {
						text: 'Total',
						align: 'left break-words',
						value: 'total',
						class: 'table-b-border black--text'
					},
                    
				],
				items: [],
				search: '',
				loading: false
			},
			
			
            exportBtnData: {
                text: "Exportar",
                icon: "",
				to: "",
				block: false,
                click: ()=>{this.export()}
            },
            

			
			
			
		}
	},

	mounted(){
		// this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.getWorks();
	},

    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        onResize () {
            
            if(window.innerWidth < 960){
                this.exportBtnData.block = true;
                
            }
            else{
                this.exportBtnData.block = false;
                
            }
        },
      
        trySearch(val){
            this.tableProps.search = val;
        },
        getWorks()
        {
            this.loadingWorks = true;
            this.$api.work.getSelect()
                .then((resp) => {
                    this.works = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingWorks = false;
                })
            ;
        },
		index() {
            if(this.workSelected == undefined || this.workSelected == "")
                return;

			this.tableProps.loading = true;
            this.subtotal = this.iva = this.total =  0 ;
            this.tableProps.items = [];
			// call api
			this.$api.report.variantsPurchaseOrdersByWork(this.workSelected)
				.then((resp) =>{
					//console.log(resp);
					this.tableProps.items = resp.data.reverse().map((x) => {
                        this.subtotal = this.subtotal + parseFloat(x.subtotal);
                        this.iva = this.iva + parseFloat(x.iva);
                        this.total = this.total + parseFloat(x.total);


                        x.subtotal = Utils.currencyFormat(x.subtotal, "");
                        x.iva = Utils.currencyFormat(x.iva, "");
                        x.total = Utils.currencyFormat(x.total, "");
                        x.price = Utils.currencyFormat(x.price, "");

                        x.variant = `${x.name} | ${x.size} | ${x.color}`;
                       
						return x;
					});

                    this.subtotal = Utils.currencyFormat(this.subtotal, "");
                    this.iva = Utils.currencyFormat(this.iva, "");
                    this.total = Utils.currencyFormat(this.total, "");
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},



		export()
        {
            if(this.workSelected == undefined || this.workSelected == "")
                return;
            
            // const workData = this.works.find((item) => item.value == this.workSelected)
            this.exportBtnData.loading = true;
            this.$api.report.exportVariantsPurchaseOrdersByWork(this.workSelected)
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `Material-por-orden.xlsx`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.exportBtnData.loading = false;
                })
            ;
        },

    }
}
</script>